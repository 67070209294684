import * as d3 from 'd3'
import styles from '../../Analytics.module.scss'

interface ID3SetupReturn {
  height: number
  width: number
  g: d3.Selection<SVGGElement, unknown, null, undefined>
  margin: Margin
  x: d3.ScaleTime<number, number, never>
  y: d3.ScaleLinear<number, number, never>
}

export function d3Setup(ref: SVGSVGElement): ID3SetupReturn {
  const svg = d3.select(ref)
  svg.selectAll('*').remove()
  const margin = { bottom: 30, left: 70, right: 20, top: 20 }
  const width = Number(svg.attr('width')) - margin.left - margin.right
  const height = Number(svg.attr('height')) - margin.top - margin.bottom
  const g = svg
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`)

  const x = d3.scaleTime().range([0, width])
  const y = d3.scaleLinear().range([height, 0])

  return { height, g, margin, width, x, y }
}

export function d3Update(d3ref: ID3SetupReturn, data: Array<[string, number]>) {
  const { x, y, height, g } = d3ref

  const line = d3
    .line<{ date: Date; count: number }>()
    .curve(d3.curveBasis)
    .x((d) => x(d.date))
    .y((d) => y(d.count))

  const parseTime = d3.timeParse('%Y-%m')

  const xDomainData = d3.extent(data, (d) => parseTime(String(d[0])))
  if (xDomainData[0]) {
    x.domain(xDomainData)
  }

  const yDomainData = d3.extent(data, (d) => {
    return d[1]
  })
  if (yDomainData[0]) {
    y.domain(yDomainData)
  }

  g.append('g')
    .attr('class', `axis ${styles.axisx}`)
    .attr('transform', `translate(0,${height})`)
    .call(d3.axisBottom(x))

  g.append('g')
    .attr('class', 'axis axis--y')
    .call(d3.axisLeft(y).ticks(4, '.2s'))

  const historicalCompletionsLine = g
    .selectAll('.historicalCompletionsLine')
    .data([data])
    .enter()
    .append('g')
    .attr('class', 'historicalCompletionsLine')

  historicalCompletionsLine
    .append('path')
    .attr('class', styles.line)
    .attr('d', (d) =>
      line(
        d.map((c) => ({
          count: c[1],
          date: parseTime(c[0]) ?? new Date(),
        })),
      ),
    )
    .style('stroke', '#6CB655')
}
