/* eslint-disable jsdoc/require-param */
/**
 * @module ActivityByYear
 */
import React from 'react'
import _ from 'lodash'
import { Box, Typography } from '@mui/material'
import byYearIcon from 'assets/byYearIcon.png'
import { useAnalytics } from 'hooks'
import PaginationBar from 'components/Plans/PaginationBar'
import Select from 'react-select'
import { NumberLike } from 'types/misc'
import UnresolvedResponseWrapper from '../UnresolvedResponseWrapper'
import ActivityTable from './table'

import styles from '../Analytics.module.scss'

const YEARS = _.range(2009, new Date().getFullYear() + 1)
const CURRENT_YEAR = YEARS[YEARS.length - 1]
const PER_PAGE = 8

const SELECT_OPTIONS = YEARS.slice() // Prevent reverse mutate orig. array.
  .reverse()
  .map((year) => ({ label: year, value: year }))

interface Props {
  /** Organizations. */
  organizations: SelectedOrg
}

const currentYearForSelect = {
  value: CURRENT_YEAR,
  label: CURRENT_YEAR,
}
/**
 * Activity By Year.
 *
 * @returns {React.ReactElement} - The activity by year jsx.
 */
export default function ActivityByYear({ organizations }: Props) {
  const [selectedYear, setSelectedYear] = React.useState<{
    label: number
    value: number
  }>(currentYearForSelect)

  const { analyticsData, loadingStatus, retry } = useAnalytics<
    Array<Array<NumberLike>>
  >({
    key: 'plan-activity-by-year',
    organizations,
    year: selectedYear.value,
  })

  const [currentPage, setCurrentPage] = React.useState(1)
  const [currentMonthOffset, setCurrentMonthOffset] = React.useState(0)

  return (
    <>
      <Box m={2}>
        <div>
          <img alt="By Year" src={byYearIcon} style={{ maxWidth: 25 }} />
        </div>
        <Typography color="textSecondary" variant="caption">
          BY YEAR
        </Typography>
        <Typography color="textPrimary" variant="h2">
          Bible Plan Activity
        </Typography>
      </Box>
      <Box data-testid="biblePlanActivitySelectWrapper" mx="auto" width={100}>
        <Select
          aria-label="Select year"
          className={styles.byYearSelect}
          defaultValue={currentYearForSelect}
          isClearable={false}
          isSearchable={false}
          onChange={setSelectedYear}
          options={SELECT_OPTIONS}
          value={selectedYear}
        />
      </Box>
      <UnresolvedResponseWrapper retry={retry} status={loadingStatus}>
        {analyticsData ? (
          <>
            <ActivityTable
              analyticsData={analyticsData}
              currentMonthOffset={currentMonthOffset}
              currentPage={currentPage}
            />
            <PaginationBar
              enableMonthRange={true}
              itemCount={PER_PAGE}
              monthOffset={currentMonthOffset}
              onMonthRangeChange={(m) => setCurrentMonthOffset(m)}
              onPageChange={(p) => setCurrentPage(p.selected + 1)}
              pageCount={Math.ceil(analyticsData?.length / PER_PAGE)}
              perPageCount={PER_PAGE}
              selected={currentPage}
              totalCount={analyticsData?.length}
            />
          </>
        ) : null}
      </UnresolvedResponseWrapper>
    </>
  )
}
