/**
 * @module BibleReferences
 */
import React from 'react'
import _ from 'lodash'
import { Box, IconButton } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

import { useTranslation } from 'react-i18next'
import BibleReferencePicker from '../bible-reference-picker'
import BibleReferencePills from '../bible-reference-pills'

interface Props {
  onChange: (references: Array<string>) => void
  usfmList: Array<string>
}

interface InfoProps {
  book: string
  chapter: number | null
  verse: number | null
  extChapter: number | null
  extVerse: number | null
}

/**
 * The Bible reference picker component.
 *
 * @alias module:BibleReferences
 *
 * @param {object} props - The component props.
 * @param {Function} props.onChange - The onChange function.
 * @param {Array<string>} [props.usfmList] - The list of USFM Bible reference strings.
 *
 * @returns {React.ReactElement} - The BibleReferencePicker component.
 *
 * @example
 * function MyComponent() {
 *   const [bibleReferences, setBibleReferences] = React.useState([])
 *
 *   return (
 *     <>
 *       <BibleReferences
 *         onChange={setBibleReferences}
 *         usfmList={bibleReferences}
 *       />
 *     </>
 *   )
 * }
 */
const BibleReferences = ({ onChange, usfmList }: Props) => {
  const [isPickerOpen, setIsPickerOpen] = React.useState(false)
  const { t } = useTranslation(['plan_days'])

  return (
    <div>
      <BibleReferencePicker
        isOpen={isPickerOpen}
        onComplete={(info: InfoProps) => {
          setIsPickerOpen(false)
          if (info) {
            let selectionCode = `${info.book}.${info.chapter}`

            if (info.verse) {
              selectionCode = `${selectionCode}.${info.verse}`
            }

            if (info.verse && info.extVerse) {
              const chapterBook = `${info.book}.${info.chapter}`

              selectionCode = _.range(info.verse, info.extVerse + 1)
                .map((verse) => `${chapterBook}.${verse}`)
                .join('+')
            }

            if (info.verse) {
              selectionCode = `${selectionCode}.${info.verse}`
            }

            onChange(_.uniq([...usfmList, selectionCode]))
          }
        }}
      />

      <Box alignItems="flex-start" display="flex">
        <IconButton
          aria-label={t(
            'plan_days:plan_day_edit.bible_reference.add_bible_reference',
          )}
          onClick={() => setIsPickerOpen(true)}
          size="small"
        >
          <AddCircleOutlineIcon />
        </IconButton>
        <Box mr={1} />
        <BibleReferencePills
          isEditing={true}
          onDeleteReference={(reference) => {
            onChange(_.without(usfmList, reference))
          }}
          references={usfmList}
        />
      </Box>
    </div>
  )
}

export default BibleReferences
