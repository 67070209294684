/**
 * @module analytics
 */
import { cleanParams } from 'helpers/remove-nulls'
import { fetchClient } from 'helpers/transport'
import { NullableNumber } from 'types/misc'

interface SelectedOrg {
  viewingAllOrganizations: boolean
  activeOrg?: Organization
}

export interface Props {
  key: string
  organizations: SelectedOrg
  year?: NullableNumber
}

interface ResponseObject<T> {
  notice?: string
  resultset: T
}

/**
 * Load analytics using proxy.
 *
 * @param {object} params - The function's params.
 * @param {string} params.key - The Analytics dataset.
 * @param {object} params.organizations - Current organization.
 * @param {number} [params.year] - The selected year, default to the current year.
 *
 * @throws {Error} - Throws an error if the loadKey/year is not supplied.
 *
 * @returns {object} - The analytics response.
 */
// eslint-disable-next-line consistent-return
export async function loadAnalytics<T>({ key, organizations, year }: Props) {
  try {
    const params: Record<string, number | string> = {}

    if (!organizations.viewingAllOrganizations && organizations.activeOrg) {
      if (!organizations.activeOrg.analytics_id) {
        throw new Error('Analytics is not available for this organization.')
      }
      params.partner = organizations.activeOrg.analytics_id
    }

    if (year) {
      params.year = year
    }
    const removeAllNullableValues = cleanParams(params)

    const param = new URLSearchParams(removeAllNullableValues)
    const response = await fetchClient<ResponseObject<T>>(
      `/analytics/${key}?${param.toString()}`,
      {
        method: 'GET',
      },
    )
    if (response.parsedBody.notice) {
      // NOTE: We've once removed this setTimeout "retry", and now added it back in.
      // See MR https://in.thewardro.be/internal-tools/partner-portal-frontend/-/merge_requests/221
      // ... and later ticket: https://lifechurch.atlassian.net/browse/PP-669
      setTimeout(() => loadAnalytics({ key, organizations, year }), 3000)
      // Without throwing here, we don't cause the promise to reject,
      // and we end up running into gross errors in the future. Try it.
      throw new Error(
        `Received a 'notice' response while fetching analytics for key: ${key}; Will retry fetching data in 3 seconds.`,
      )
    }

    return response.parsedBody.resultset
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message)
    }
  }
}
