import React from 'react'
import PaginationBar from 'components/Plans/PaginationBar'
import { useAnalytics } from 'hooks'
import { Box } from '@mui/material'
import UnresolvedResponseWrapper from '../../UnresolvedResponseWrapper'
import styles from '../../Analytics.module.scss'

interface Props {
  selectedOrganization: SelectedOrg
}

const TOP_PLANS_PER_PAGE = 10

export default function Completions({ selectedOrganization }: Props) {
  const { analyticsData, loadingStatus, retry } =
    useAnalytics<StringNumberArray>({
      key: 'top-plans-completions',
      organizations: selectedOrganization,
    })

  const [currentPage, setCurrentPage] = React.useState(1)

  const index = (currentPage - 1) * TOP_PLANS_PER_PAGE
  const items = analyticsData?.slice(index, index + TOP_PLANS_PER_PAGE) ?? []

  return (
    <Box maxWidth={400} mr={1}>
      <UnresolvedResponseWrapper retry={retry} status={loadingStatus}>
        <>
          <table className={styles.analyticsTable}>
            <thead>
              <tr>
                <th>Plan</th>
                <th className={styles.topPlansTableHead}>By Completions</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, idx) => (
                <tr key={idx}>
                  <td style={{ textAlign: 'left' }}>{item[1]}</td>
                  <td style={{ textAlign: 'right' }}>{item[2]}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <PaginationBar
            itemCount={TOP_PLANS_PER_PAGE}
            onPageChange={(p) => setCurrentPage(p.selected + 1)}
            pageCount={Math.ceil(
              analyticsData ? analyticsData.length / TOP_PLANS_PER_PAGE : 1,
            )}
            perPageCount={TOP_PLANS_PER_PAGE}
            selected={currentPage}
            totalCount={analyticsData?.length || 1}
          />
        </>
      </UnresolvedResponseWrapper>
    </Box>
  )
}
