/* eslint-disable react/no-multi-comp */
import React, { FC } from 'react'
import { BrowserRouter, useLocation } from 'react-router-dom'
import { Dispatch, bindActionCreators } from 'redux'
import { Provider, connect } from 'react-redux'
import {
  ToastProvider,
  ThemeProvider as YVThemeProvider,
  themeClass,
} from '@youversion/design-system'
import { getV3ThemeObject } from '@youversion/react/styles'
import {
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from '@mui/material'
import * as AppActions from 'state/actions/app'
import AppRoutes from 'routes'
import Root from 'components/Root/Root'
import 'moment/min/locales'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useTranslation } from 'react-i18next'
import createCache from '@emotion/cache'
// @ts-ignore
import { prefixer } from 'stylis'
import rtlPlugin from 'stylis-plugin-rtl'
import { CacheProvider } from '@emotion/react'

/**
 * Scrolls to the top on route changes.
 * Replaces behavior of legacy middleware `react-router-scroll`.
 *
 * @see Https://reactrouter.com/web/guides/scroll-restoration.
 *
 * @returns {null} - Does not return anything, it's just scrolling on route change.
 */
function ScrollToTop() {
  const { pathname } = useLocation()
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

// if (process.env.NODE_ENV !== 'production') {
//   const worker = setupWorker()
//   worker.start()
// }
interface Props {
  appRouteChanged: () => void
  store: any
}

export const queryClient = new QueryClient()

// We only want the rtlPlugin included in RTL
const cacheRtl = createCache({
  key: 'mui-rtl',
  stylisPlugins: [prefixer, rtlPlugin],
  prepend: true,
})

const cacheLtr = createCache({
  key: 'mui-ltr',
  stylisPlugins: [prefixer],
  prepend: true,
})

const App: FC<Props> = ({ appRouteChanged, store }) => {
  // When it's time to allow the user to change from light to dark mode,
  // the `setIsDark` function can be retrieved from the state to be called.
  const [isDark] = React.useState<boolean>(false)
  const { i18n } = useTranslation()
  const dir = i18n.dir()
  const lang = i18n.language

  const theme = React.useMemo<object>(() => {
    const base = getV3ThemeObject({ isDarkMode: isDark })
    return createTheme({
      ...base,
      direction: dir,
    })
  }, [isDark, dir])

  // This is YVui's way for handling the theme by checking for a 'data-theme' property.
  React.useEffect(() => {
    window.document.documentElement.setAttribute(
      'data-theme',
      isDark ? 'dark' : 'light',
    )
  }, [isDark])

  // Sync the language dir to the root element for right-to-left support
  React.useEffect(() => {
    document.documentElement.dir = dir
    document.documentElement.lang = lang
  }, [dir, lang])

  return (
    <QueryClientProvider client={queryClient}>
      <CacheProvider value={dir === 'rtl' ? cacheRtl : cacheLtr}>
        <YVThemeProvider themeClass={themeClass}>
          <ToastProvider
            config={{
              duration: 5000,
              max: 5,
              position: 'blockStartInlineEnd',
            }}
          >
            <MuiThemeProvider theme={theme}>
              <CssBaseline />
              <Provider store={store}>
                <BrowserRouter>
                  <Root>
                    <ScrollToTop />
                    <AppRoutes appRouteChanged={appRouteChanged} />
                  </Root>
                </BrowserRouter>
              </Provider>
            </MuiThemeProvider>
          </ToastProvider>
        </YVThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'} />
      </CacheProvider>
    </QueryClientProvider>
  )
}

const stateToProps = () => {
  return {}
}

const dispatchToProps = (dispatch: Dispatch) => {
  return {
    appRouteChanged: bindActionCreators(AppActions.appRouteChanged, dispatch),
  }
}

export default connect(stateToProps, dispatchToProps)(App)
