/* eslint-disable react/no-multi-comp */
/**
 * @module Analytics
 */
import { Box, CircularProgress } from '@mui/material'
import { useSelector } from 'react-redux'
import { State } from 'state/reducers'

import AnalyticsComponents from './AnalyticsComponents'

const mapStateToProps = (state: State) => state.organizations

export function Analytics() {
  const organizations = useSelector(mapStateToProps) as SelectedOrg
  if (
    Boolean(organizations.viewingAllOrganizations) ||
    Boolean(organizations.activeOrg)
  ) {
    return <AnalyticsComponents organizations={organizations} />
  }

  return (
    <Box
      alignItems={'center'}
      display={'flex'}
      justifyContent={'center'}
      marginTop={20}
    >
      <CircularProgress />
    </Box>
  )
}

export default Analytics
