/* eslint-disable jsdoc/require-param */
/* eslint-disable no-nested-ternary */
/**
 * @module AnalyticsOverview
 */
import React from 'react'
import styles from './styles.module.scss'
import TotalSubscriptions from './total-subscriptions'
import TotalCompletions from './total-completions'
import PerDaySubScriptions from './per-day-subscriptions'
import PerDayCompletions from './per-day-completions'

interface Props {
  /** Organization object. */
  organizations: SelectedOrg
}

/**
 * Analytics Overview.
 *
 * @returns {React.ReactElement} - The historical activity jsx.
 */
function Overview({ organizations }: Props) {
  return (
    <div className={styles.containerStyle} id="analytics-overview">
      <div
        className={styles.rowStyle}
        style={{
          borderBlockEnd: '1px solid #F0F0F0',
        }}
      >
        <TotalSubscriptions selectedOrganization={organizations} />
        <TotalCompletions selectedOrganization={organizations} />
      </div>
      <div className={styles.rowStyle}>
        <PerDaySubScriptions organizations={organizations} />
        <PerDayCompletions selectedOrganization={organizations} />
      </div>
    </div>
  )
}

export default Overview
