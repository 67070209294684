import * as d3 from 'd3'
import styles from '../Analytics.module.scss'

interface SetupProps {
  g: any
  height: number
  margin: { bottom: number; left: number; right: number; top: number }
  width: number
  x: any
  y: any
}

export const d3Setup = (ref: React.RefObject<SVGSVGElement>): SetupProps => {
  const svg = d3.select<SVGSVGElement, unknown>(ref.current as SVGSVGElement)
  svg.selectAll<SVGGElement, unknown>('*').remove()
  const margin = { bottom: 30, left: 70, right: 20, top: 20 }
  const width = parseInt(svg.attr('width'), 10) - margin.left - margin.right
  const height = parseInt(svg.attr('height'), 10) - margin.top - margin.bottom
  const g = svg
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`)

  const x = d3.scaleTime().range([0, width])
  const y = d3.scaleLinear().range([height, 0])

  return { g, height, margin, width, x, y }
}

export const d3Update = (d3ref: SetupProps, data: Array<Array<string>>) => {
  const { x, y, height, g } = d3ref

  const line = d3
    .line<{ date: Date | null; count: string }>()
    .curve(d3.curveBasis)
    .x((d) => {
      return x(d.date)
    })
    .y((d) => {
      return y(d.count)
    })

  const parseTime = d3.timeParse('%Y-%m-%d')

  const subscriptionCompletionss = ['sub', 'comp'].map((id, idx) => {
    return {
      id,
      values: data.map((d) => {
        // idx + 1 === MAGIC. TODO: Explain
        return { count: d[idx + 1], date: parseTime(d[0]) }
      }),
    }
  })

  x.domain(
    d3.extent(data, (d) => {
      return parseTime(d[0])
    }),
  )

  y.domain([
    d3.min(subscriptionCompletionss, (c) => {
      return d3.min(c.values, (d) => {
        return d.count
      })
    }),
    d3.max(subscriptionCompletionss, (c) => {
      return d3.max(c.values, (d) => {
        return d.count
      })
    }),
  ])

  g.append('g')
    .attr('class', `axis ${styles.axisx}`)
    .attr('transform', `translate(0,${height})`)
    .call(d3.axisBottom(x))

  g.append('g')
    .attr('class', 'axis axis--y')
    .call(d3.axisLeft(y).ticks(4, '.0s'))

  const dataLine = g
    .selectAll('.dataLine')
    .data(subscriptionCompletionss)
    .enter()
    .append('g')
    .attr('class', 'dataLine')

  dataLine
    .append('path')
    .attr('class', styles.line)
    .attr('d', (d: { values: { count: string; date: Date | null }[] }) => {
      return line(d.values)
    })
    .style('stroke', (d: { id: string }) => {
      return d.id === 'sub' ? '#6CB655' : '#008CBA'
    })
}
