/* eslint-disable jsdoc/require-param */
/**
 * @module HistoricalActivity
 */
import React from 'react'
import historicalIcon from 'assets/historicalIcon.png'
import { Box, Typography } from '@mui/material'
import { HistoricalActivityDataTable } from './DataTable'
import styles from '../Analytics.module.scss'

interface Props {
  /** The organizations. */
  organizations: SelectedOrg
}

/**
 * Historical Activity.
 *
 * @returns {React.ReactElement} - The Historical Activity analytics jsx.
 */
export default function HistoricalActivity({ organizations }: Props) {
  return (
    <>
      <Box mt={5}>
        <img alt="History" src={historicalIcon} style={{ maxWidth: 25 }} />
      </Box>

      <Typography color="textSecondary" variant="caption">
        ALL TIME
      </Typography>
      <Typography color="textPrimary" variant="h2">
        Bible Plan Historical Activity
      </Typography>
      <div className={styles.containerStyle}>
        <HistoricalActivityDataTable organizations={organizations} />
      </div>
    </>
  )
}
