/* eslint-disable jsdoc/require-param */
import * as React from 'react'
import _ from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import { NumberLike } from 'types/misc'
import styles from '../../Analytics.module.scss'

const PER_PAGE = 8

const MONTH_NAMES = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

const MONTHS_OFFSET_ARR = [4, 5, 6, 7, 8, 9]

const useStyles = makeStyles({
  completedColumnBody: {
    fontWeight: 'bold',
    lineHeight: '1.8em',
    minWidth: 135,
    paddingInlineEnd: 20,
    textAlign: 'left',
  },
  totalColumnTableBody: {
    borderBlockEndWidth: 0,
    paddingInlineStart: 25,
  },
})

interface Props {
  /** Analytics Data Response. */
  analyticsData: Array<Array<NumberLike>>
  /** Number of months to offset the data. */
  currentMonthOffset: number
  /** Number of the current page. */
  currentPage: number
}

/**
 * Activity By Year.
 *
 * @returns {React.ReactElement} - The activity by year jsx.
 */
export default function ActivityTable({
  analyticsData,
  currentMonthOffset,
  currentPage,
}: Props) {
  const classes = useStyles()

  const byId = React.useMemo(() => {
    const getDataById: Record<string, any> = {}

    analyticsData?.forEach((key) => {
      const currId = key[0]
      if (!getDataById[currId]) {
        getDataById[currId] = {
          [key[3]]: key,
        }
      } else {
        getDataById[currId][key[3]] = key
      }
    })

    return getDataById
  }, [analyticsData])

  const index = React.useMemo(() => (currentPage - 1) * PER_PAGE, [currentPage])
  const items = React.useMemo(() => {
    const ids = Object.keys(byId)
    return ids.slice(index, index + PER_PAGE) || []
  }, [byId, index])

  return (
    <table className={styles.analyticsTable} style={{ textAlign: 'left' }}>
      <thead>
        <tr>
          <th>Plan</th>
          <th style={{ width: 120 }}>{/* Blank for Sub/Comp */}</th>
          <th className={styles.monthTh}>
            {MONTH_NAMES[0 + currentMonthOffset]}
          </th>
          <th className={styles.monthTh}>
            {MONTH_NAMES[1 + currentMonthOffset]}
          </th>
          <th className={styles.monthTh}>
            {MONTH_NAMES[2 + currentMonthOffset]}
          </th>
          <th className={styles.monthTh}>
            {MONTH_NAMES[3 + currentMonthOffset]}
          </th>
          <th className={styles.monthTh}>
            {MONTH_NAMES[4 + currentMonthOffset]}
          </th>
          <th className={styles.monthTh}>
            {MONTH_NAMES[5 + currentMonthOffset]}
          </th>
          <th className={styles.activityByYearTotalColumnTableHead}>Total</th>
        </tr>
      </thead>
      <tbody className={styles.byYearTbody}>
        {items.map((id) => {
          const datum = byId[id]
          const completes = datum.completes || _.fill(Array(17), 'N/A')
          const subscribes = datum.subscribes || _.fill(Array(17), 'N/A')
          const subscribesTotal = subscribes[16]
          const completesTotal = completes[16]

          return (
            <tr key={id}>
              <td className={classes.completedColumnBody}>{completes[1]}</td>
              <td>
                <div>Subscribes</div>
                <div>Completions</div>
              </td>
              {MONTHS_OFFSET_ARR.map((x) => (
                <td key={x}>
                  <div>{subscribes[x + currentMonthOffset]}</div>
                  <div>{completes[x + currentMonthOffset]}</div>
                </td>
              ))}
              <td className={classes.totalColumnTableBody}>
                <div>{subscribesTotal}</div>
                <div>{completesTotal}</div>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
