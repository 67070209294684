import { Typography } from '@mui/material'
import { useAnalytics } from 'hooks'
import UnresolvedResponseWrapper from '../../UnresolvedResponseWrapper'
import styles from '../styles.module.scss'

interface Props {
  selectedOrganization: SelectedOrg
}

export default function PerDayCompletions({ selectedOrganization }: Props) {
  const { analyticsData, loadingStatus, retry } = useAnalytics({
    key: 'per-day-completion-rate',
    organizations: selectedOrganization,
  })

  const perDayComp = Array.isArray(analyticsData) ? analyticsData[0]?.[0] : 0

  return (
    <div
      className={styles.topBoxStyle}
      style={{
        justifyContent: 'flex-end',
      }}
    >
      <Typography color="textSecondary" variant="caption">
        Last 7 Days
      </Typography>
      <Typography color="textPrimary" gutterBottom={true} variant="body1">
        Per Day Completions
      </Typography>
      <UnresolvedResponseWrapper retry={retry} status={loadingStatus}>
        <div className={styles.bigNumberStyle}>
          <Typography component="p" variant="h2">
            {Math.round(perDayComp).toLocaleString()}
          </Typography>
        </div>
      </UnresolvedResponseWrapper>
    </div>
  )
}
