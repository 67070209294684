/* eslint-disable react/no-multi-comp */
/* eslint-disable jsdoc/require-param */
/* eslint-disable no-nested-ternary */
/**
 * @module PlanSubmitter
 */
import { useAuth } from 'auth'
import { submissionStatuses } from 'helpers/constants'
import { MissingNarratedAudioDay } from 'context'
import { Box } from '@youversion/design-system'
import SubmitPlanButton from '../submit-plan-button'
import { ApproveAllButton } from '../approve-all-button'
import { Plan } from '../types'

import { ExportPlanButton, UnlinkPlanButton } from '../Buttons'
import { TransferButton } from '../AdminBar/Buttons/TransferButton'

interface PlanSubmitterProps {
  plan: Plan.Plan
  planDays: Plan.Day[]
  planLanguage: Language
  getMissingNarratedAudioDays?: () => Array<MissingNarratedAudioDay> | null
}

/**
 * Module for submitting, exporting and approving plans.
 *
 * @returns {ReactElement} - Buttons for specific state.
 */
export default function PlanSubmitter({
  plan,
  planDays,
  planLanguage,
  getMissingNarratedAudioDays,
}: PlanSubmitterProps) {
  const { user } = useAuth()

  if (!user) {
    return null
  }

  const planStatus = plan.overall_status

  return (
    <Box display="flex" flexDirection="row">
      {user.can('edit_unpublished:plan_day') &&
      planStatus === submissionStatuses.SUBMITTED ? (
        <ApproveAllButton plan={plan} planStatus={planStatus} />
      ) : user.can('create:plan_export') &&
        planStatus !== submissionStatuses.DRAFT ? (
        <ExportPlanButton planId={plan.id} />
      ) : (
        <SubmitPlanButton
          getMissingNarratedAudioDays={getMissingNarratedAudioDays}
          plan={plan}
          planDays={planDays}
          planLanguage={planLanguage}
        />
      )}
      {/* TransferButton and UnlinkButton is rendered here for now, but will be moved the AdminBar */}
      {user.can('administrate:all_permissions') && (
        <TransferButton planId={plan.id} />
      )}
      {user.can('administrate:all_permissions') && plan.source_id ? (
        <UnlinkPlanButton planId={plan.id} />
      ) : null}
    </Box>
  )
}
