/**
 * @module AnalyticsTotalCompletions
 */

import { Typography } from '@mui/material'
import { useAnalytics } from 'hooks'
import UnresolvedResponseWrapper from '../../UnresolvedResponseWrapper'
import styles from '../styles.module.scss'

interface SelectedOrg {
  viewingAllOrganizations: boolean
  activeOrg?: Organization
}

interface Props {
  selectedOrganization: SelectedOrg
}

export default function TotalCompletions({ selectedOrganization }: Props) {
  const { analyticsData, loadingStatus, retry } =
    useAnalytics<NumberArrayResultSet>({
      key: 'rp-completed',
      organizations: selectedOrganization,
    })

  const totalCompleted = analyticsData?.length ? analyticsData[0]?.[0] : 0

  return (
    <div className={styles.topBoxStyle}>
      <Typography color="textSecondary" variant="caption">
        Total
      </Typography>
      <Typography color="textPrimary" gutterBottom={true} variant="body1">
        Completions For All My Plans
      </Typography>

      <UnresolvedResponseWrapper retry={retry} status={loadingStatus}>
        <div className={styles.bigNumberStyle}>
          <Typography component="p" variant="h2">
            {Math.round(totalCompleted).toLocaleString()}
          </Typography>
        </div>
      </UnresolvedResponseWrapper>
    </div>
  )
}
