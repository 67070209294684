import React, { VFC } from 'react'
import { Link } from 'react-router-dom'
import { Box, Chip } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  chip: {
    '&:hover': {
      color: theme.palette.text.primary,
      cursor: 'pointer',
      filter: 'brightness(0.9)',
    },
    color: theme.palette.text.primary,
  },
  container: {
    gap: theme.spacing(1),
  },
  selectedChip: {
    '&:hover': {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      cursor: 'pointer',
      filter: 'brightness(0.9)',
    },
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
}))

interface PathObject {
  /**
   * Determines if the user has permission.
   */
  can?: boolean
  /**
   * Determines if the chip component is selected.
   */
  isSelected?: boolean
  /**
   * Label of the page.
   */
  label: string
  /**
   * The route to get to the page.
   */
  to: string
}

interface Props {
  /**
   * The path object containing data to use for the Chip switcher.
   */
  paths: Array<PathObject>
}

/*
 * Chip Switcher - A row of chips to switch between pages.
 */
export const PageChipSwitcher: VFC<Props> = ({ paths }) => {
  const classes = useStyles()

  return (
    <Box className={classes.container} display="flex">
      {paths.map(({ can = false, isSelected = false, label, to }) =>
        can ? (
          <Chip
            className={isSelected ? classes.selectedChip : classes.chip}
            color={isSelected ? 'primary' : 'default'}
            component={Link}
            key={to}
            label={label}
            to={to}
          />
        ) : null,
      )}
    </Box>
  )
}
