import { Typography } from '@mui/material'
import { useAnalytics } from 'hooks'
import UnresolvedResponseWrapper from '../../UnresolvedResponseWrapper'
import styles from '../styles.module.scss'

interface Props {
  organizations: SelectedOrg
}

export default function PerDaySubscriptions({ organizations }: Props) {
  const { analyticsData, loadingStatus, retry } =
    useAnalytics<NumberArrayResultSet>({
      key: 'per-day-subscription-rate',
      organizations,
    })

  const perDaySub = analyticsData?.length ? analyticsData[0]?.[0] : 0

  return (
    <div
      className={styles.topBoxStyle}
      style={{
        borderInlineEnd: '1px solid #F0F0F0',
        justifyContent: 'flex-end',
      }}
    >
      <Typography color="textSecondary" variant="caption">
        Last 7 Days
      </Typography>
      <Typography color="textPrimary" gutterBottom={true} variant="body1">
        Per Day Subscriptions
      </Typography>
      <UnresolvedResponseWrapper retry={retry} status={loadingStatus}>
        <div className={styles.bigNumberStyle}>
          <Typography component="p" variant="h2">
            {Math.round(perDaySub).toLocaleString()}
          </Typography>
        </div>
      </UnresolvedResponseWrapper>
    </div>
  )
}
