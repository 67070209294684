import React from 'react'
import { Box, Typography } from '@mui/material'
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined'
import Overview from 'components/Analytics/Overview'
import ActivityByYear from 'components/Analytics/ActivityByYear'
import HistoricalActivity from 'components/Analytics/HistoricalActivity'
import TopPlans from 'components/Analytics/TopPlans'
import SubCompChart from 'components/Analytics/SubCompChart'
import HistoricalCompChart from 'components/Analytics/HistoricalCompChart'
import styles from 'components/Analytics/Analytics.module.scss'
import YoyInfoBoxes from '../YoyInfoBoxes'

export interface Props {
  organizations: SelectedOrg
}

const AnalyticsComponents = ({ organizations }: Props): JSX.Element => {
  return (
    <div className={styles.analyticsContainer}>
      <Box alignItems="center" display="flex" flexDirection="column">
        <AssessmentOutlinedIcon fontSize="large" />
        <Typography color="textSecondary" variant="caption">
          Totals
        </Typography>
        <Typography color="textPrimary" variant="h2">
          All Plans
        </Typography>
      </Box>

      <Overview organizations={organizations} />
      <SubCompChart organizations={organizations} />
      <YoyInfoBoxes organizations={organizations} />

      <HistoricalCompChart organizations={organizations} />

      <TopPlans organizations={organizations} />

      <HistoricalActivity organizations={organizations} />
      <ActivityByYear organizations={organizations} />
      {/* 
 
       <div>
         <img alt="History" src={historicalIcon} style={{ maxWidth: 25 }} />
       </div>
 
       <div style={subtextStyle}>ALL TIME</div>
       <div style={headingStyle}>Bible Plan Historical Activity</div>
 
       <HistoricalActivity
         error={error}
         historicalActivity={value}
         isLoading={isLoading}
         retry={loadOrgAnalytics}
       /> */}
    </div>
  )
}

export default AnalyticsComponents
