/* eslint-disable jsdoc/require-param */
import React, { useMemo } from 'react'
import { useAnalytics } from 'hooks'
import PaginationBar from 'components/Plans/PaginationBar'
import { NumberLike } from 'types/misc'
import UnresolvedResponseWrapper from '../../UnresolvedResponseWrapper'
import styles from '../../Analytics.module.scss'

const PER_PAGE = 8

interface Props {
  /** The organizations. */
  organizations: SelectedOrg
}

/**
 * Historical Activity Datatable.
 *
 * @returns {React.ReactElement} - The Historical Activity analytics datatable.
 */
export function HistoricalActivityDataTable({ organizations }: Props) {
  const { analyticsData, loadingStatus, retry } = useAnalytics<
    Array<Array<NumberLike>>
  >({
    key: 'plan-historical',
    organizations,
  })

  const [currentPage, setCurrentPage] = React.useState(1)

  const index = useMemo(() => (currentPage - 1) * PER_PAGE, [currentPage])

  const items = useMemo(() => {
    if (analyticsData) {
      return analyticsData.slice(index, index + PER_PAGE)
    }

    return []
  }, [analyticsData, index])

  return (
    <div>
      <UnresolvedResponseWrapper retry={retry} status={loadingStatus}>
        <table className={styles.analyticsTable} style={{ textAlign: 'left' }}>
          <tfoot>
            <tr>
              <td colSpan={99}>
                <p>
                  Plan Rating is based on a 5-star scale. The current average
                  rating is 4.65.
                </p>
              </td>
            </tr>
          </tfoot>

          <thead>
            <tr>
              <th>Plan</th>
              <th style={{ width: 120 }}>Days</th>
              <th className={styles.historicalActivityTableHeaders}>
                Unique User Completions
              </th>
              <th className={styles.historicalActivityTableHeaders}>
                Subscriptions
              </th>
              <th className={styles.historicalActivityTableHeaders}>
                Completions
              </th>
              <th className={styles.historicalActivityTableHeaders}>
                Completion Rate
              </th>
              <th className={styles.historicalActivityTableHeaders}>Rating</th>
              <th className={styles.historicalActivityTableHeaders}>Ratings</th>
            </tr>
          </thead>

          <tbody className={styles.byYearTbody}>
            {items &&
              items.map((i) => {
                const id = i[0]
                const planName = i[1]
                const days = i[4]
                const uniqueUserCompletions = i[5]
                const subscriptions = i[6]
                const completions = i[7]
                const completionRate = i[8]
                const rating = i[2]
                const numOfRatings = i[3]

                return (
                  <tr key={id}>
                    <td className={styles.historicalActivityPlanDaysColumn}>
                      {planName}
                    </td>
                    <td>{days}</td>
                    <td>{uniqueUserCompletions}</td>
                    <td>{subscriptions}</td>
                    <td>{completions}</td>
                    <td>{completionRate}%</td>
                    <td>{rating}</td>
                    <td>{numOfRatings}</td>
                  </tr>
                )
              })}
          </tbody>
        </table>
        <PaginationBar
          itemCount={PER_PAGE}
          onPageChange={(p) => setCurrentPage(p.selected + 1)}
          pageCount={Math.ceil(analyticsData?.length ?? 0 / PER_PAGE)}
          perPageCount={PER_PAGE}
          selected={currentPage}
          totalCount={analyticsData?.length ?? 0}
        />
      </UnresolvedResponseWrapper>
    </div>
  )
}
