/* eslint-disable jsdoc/require-param */
/**
 * @module HistoricalCompChart
 */
import React from 'react'
import { Box, Typography } from '@mui/material'
import { useAnalytics } from 'hooks'
import { d3Setup, d3Update } from './d3'
import UnresolvedResponseWrapper from '../UnresolvedResponseWrapper'
import styles from '../Analytics.module.scss'

interface Props {
  /** Organizations. */
  organizations: SelectedOrg
}

/**
 * Historical Comp Chart.
 *
 * @returns {React.ReactElement} - The historical completed chart.
 */
function HistoricalCompChart({ organizations }: Props) {
  const svgRef = React.useRef(null)

  const { analyticsData, loadingStatus, retry } = useAnalytics<
    Array<[string, number]>
  >({
    key: 'historical-completions',
    organizations,
  })

  React.useEffect(() => {
    if (svgRef.current && analyticsData && analyticsData?.length > 0) {
      const d3ref = d3Setup(svgRef.current)
      d3Update(d3ref, analyticsData)
    }
  }, [analyticsData, loadingStatus])

  return (
    <Box
      alignItems="center"
      border={1}
      borderColor="grey.200"
      display="flex"
      flexDirection="column"
      height={540}
      mt={4}
      padding={4}
    >
      <Typography color="textPrimary" variant="h2">
        Historical Completions by Month
      </Typography>
      <div className={styles.fileLoadWrapper}>
        <UnresolvedResponseWrapper retry={retry} status={loadingStatus}>
          <svg
            data-testid="historicalCompChart"
            height="400"
            ref={svgRef}
            width="920"
          />
        </UnresolvedResponseWrapper>
      </div>
    </Box>
  )
}

export default HistoricalCompChart
