import { Plan } from 'components/Plans/types'
/**
 * @module useCategories
 */

import React from 'react'
import _ from 'lodash'
import { getCategories } from 'api/plans'
import { Empty, NumberLike } from 'types/misc'

type CategoriesById = Record<NumberLike, Plan.Category>

interface CategoriesProp {
  allCategoryIds: Array<NumberLike>
  categoriesById: CategoriesById
  categorySections: Array<string>
}

/**
 * GET all categories.
 *
 *  @alias useCategories
 *
 * @returns {Array} - All categories.
 *
 * @example
 * import { useCategories } from 'hooks'
 *
 * function MyComponent() {
 *   const categories = useCategories()
 *
 *   return (
 *     <>
 *       {categories.map((category) => console.log(category)}
 *     </>
 *   )
 * }
 
 */
// eslint-disable-next-line import/prefer-default-export
export function useCategories() {
  const [categories, setCategories] = React.useState<CategoriesProp | Empty>({})

  React.useEffect(() => {
    async function loadCategories() {
      const categoriesResponse = await getCategories()
      if (categoriesResponse) {
        const categoriesIds = categoriesResponse.map((category) => category.id)

        const flatCatIds: Array<NumberLike> = []
        const flatCatsById: CategoriesById = {}
        let categorySections: Array<string> = []

        _.forEach(categoriesIds, (cid) => {
          const metaCategory = categoriesResponse[cid - 1]

          _.forEach(Object.keys(metaCategory.data[0]), (section) => {
            _.forEach(metaCategory.data[0][section], (actualId) => {
              if (!flatCatsById[actualId]) {
                flatCatIds.push(actualId)
                flatCatsById[actualId] = {
                  id: actualId,
                  name: actualId,
                  sections: [section],
                }

                categorySections = _.uniq([...categorySections, section])
              } else {
                flatCatsById[actualId].sections = [
                  ...flatCatsById[actualId].sections,
                  section,
                ]
              }
            })
          })
        })
        setCategories({
          allCategoryIds: flatCatIds,
          categoriesById: flatCatsById,
          categorySections,
        })
      }
    }
    loadCategories()
  }, [])

  return categories
}
