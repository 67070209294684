/**
 * @module AnalyticsTotalSubscriptions
 */

import { useMemo } from 'react'
import { Typography } from '@mui/material'
import { useAnalytics } from 'hooks'
import { NumberLike } from 'types/misc'
import UnresolvedResponseWrapper from '../../UnresolvedResponseWrapper'
import styles from '../styles.module.scss'

interface Props {
  selectedOrganization: SelectedOrg
}

export default function TotalSubscriptions({ selectedOrganization }: Props) {
  const { analyticsData, loadingStatus, retry } =
    useAnalytics<NumberArrayResultSet>({
      key: 'plan-historical',
      organizations: selectedOrganization,
    })
  const totalSubscriptions = useMemo(() => {
    if (analyticsData) {
      return analyticsData?.reduce((acc: number, cur: Array<NumberLike>) => {
        const total = cur[6]
        if (typeof total === 'number') {
          return acc + total
        }
        return acc
      }, 0)
    }
    return 0
  }, [analyticsData])

  return (
    <div
      className={styles.firstHeader}
      style={{
        borderInlineEnd: '1px solid #F0F0F0',
      }}
    >
      <Typography color="textSecondary" variant="caption">
        Total
      </Typography>
      <Typography color="textPrimary" gutterBottom={true} variant="body1">
        Subscriptions For All My Plans
      </Typography>
      <UnresolvedResponseWrapper retry={retry} status={loadingStatus}>
        <div className={styles.bigNumberStyle}>
          <Typography component="p" variant="h2">
            {totalSubscriptions?.toLocaleString()}
          </Typography>
        </div>
      </UnresolvedResponseWrapper>
    </div>
  )
}
