import React from 'react'
import { Box, Typography } from '@mui/material'
import topPlansSymbol from 'assets/topPlansSymbol.png'
import Subscribers from './Subscribers'
import Completions from './Completions'

export interface Props {
  organizations: SelectedOrg
}

const TopPlans = ({ organizations }: Props): JSX.Element => {
  return (
    <Box>
      <Box mt={4}>
        <img alt="Top Plans" src={topPlansSymbol} style={{ maxWidth: 25 }} />
      </Box>

      <Typography color="textSecondary" variant="caption">
        LAST 30 DAYS
      </Typography>
      <Typography color="textPrimary" variant="h2">
        Top Plans
      </Typography>
      <Box display="flex" justifyContent="space-around">
        <Subscribers selectedOrganization={organizations} />
        <Completions selectedOrganization={organizations} />
      </Box>
    </Box>
  )
}

export default TopPlans
