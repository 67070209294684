import { Box, Button, CircularProgress } from '@mui/material'
import { Autorenew } from '@mui/icons-material'
import { statusTypes } from '@youversion/utils'
import ErrorIcon from '../ErrorIcon'

import styles from '../Overview/styles.module.scss'

interface Props {
  children: React.ReactNode
  status: string
  retry: VoidFunction
}

export default function UnresolvedResponseWrapper({
  children,
  status,
  retry,
}: Props) {
  if (status === statusTypes.REJECTED) {
    return (
      <>
        <div className={styles.errorContainer}>
          Failed to load data
          <ErrorIcon message={'Click button below to retry'} />
        </div>
        <Button
          color="secondary"
          onClick={retry}
          startIcon={<Autorenew />}
          variant="contained"
        >
          Retry
        </Button>
      </>
    )
  }

  if (status !== statusTypes.RESOLVED) {
    return (
      <Box m={2}>
        <CircularProgress aria-label="Loading" size={30} />
      </Box>
    )
  }

  return <>{children}</>
}
