import React from 'react'
import { Box, Typography } from '@mui/material'
import { useAnalytics } from 'hooks'
import { d3Setup, d3Update } from './d3'
import UnresolvedResponseWrapper from '../UnresolvedResponseWrapper'
import styles from '../Analytics.module.scss'

export interface Props {
  organizations: SelectedOrg
}

const SubCompChart = ({ organizations }: Props): JSX.Element => {
  const svgRef = React.useRef(null)

  const { analyticsData, loadingStatus, retry } = useAnalytics<
    Array<Array<string>>
  >({
    key: 'completions-by-day',
    organizations,
  })
  React.useEffect(() => {
    if (svgRef.current && analyticsData?.length) {
      const d3Ref = d3Setup(svgRef)
      d3Update(d3Ref, analyticsData)
    }
    // loadingStatus was intentionally added to re-render the component
    // when d3 updates svgRef
  }, [analyticsData, loadingStatus])

  const subsKey = {
    backgroundColor: '#6CB655',
    display: 'inline-block',
    height: 2,
    margin: '0 5px 2px',
    width: 10,
  }
  const compsKey = { ...subsKey, backgroundColor: '#008CBA' }

  return (
    <Box
      alignItems="center"
      border={1}
      borderColor="grey.200"
      display="flex"
      flexDirection="column"
      height={540}
      mt={4}
      padding={4}
    >
      <Typography color="textPrimary" variant="h2">
        Completions By Day
      </Typography>
      <Box m={1}>
        By day:
        <span style={subsKey} />
        subscribes
        <span style={compsKey} />
        completes
      </Box>
      <div className={styles.fileLoadWrapper}>
        <UnresolvedResponseWrapper retry={retry} status={loadingStatus}>
          <svg
            data-testid="subCompChart"
            height="400"
            ref={svgRef}
            width="820"
          />
        </UnresolvedResponseWrapper>
      </div>
    </Box>
  )
}

export default SubCompChart
