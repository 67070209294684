import PropTypes from 'prop-types'
import Icon from 'components/Icons/ErrorInfo'
import ReactTooltip from 'react-tooltip'
import { v4 as uuidv4 } from 'uuid'

import styles from '../Analytics.module.scss'

interface Props {
  message: string
}

const ErrorIcon = ({ message }: Props) => {
  const tipId = uuidv4()

  return (
    <span className={styles.error} data-for={tipId} data-tip={message}>
      <Icon />
      <ReactTooltip id={tipId} />
    </span>
  )
}

ErrorIcon.propTypes = {
  message: PropTypes.string.isRequired,
}

export default ErrorIcon
