import * as React from 'react'
import YoySubscribes from './subscribers'
import YoyCompletions from './completions'

import styles from './styles.module.scss'

export interface Props {
  organizations: SelectedOrg
}

const YoyInfoBoxes = ({ organizations }: Props): JSX.Element => {
  return (
    <div className={styles.infoBoxesContainer}>
      <YoySubscribes selectedOrganization={organizations} />
      <YoyCompletions selectedOrganization={organizations} />
    </div>
  )
}

export default YoyInfoBoxes
