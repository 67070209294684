/**
 * @module MuiNavLink
 */
import React, { FC, ForwardedRef } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import classnames from 'classnames'

export interface MuiNavLinkProps extends NavLinkProps {
  ref: ForwardedRef<HTMLAnchorElement>
}

/**
 * Custom wrapper to interface React Router's NavLink component with MaterialUI's component api.
 *
 * React Router accepts a function or string for `className`, passing down an `isActive` param. This allows a `className` to be conditionally defined.
 *
 * Material-UI only accepts and passes a string for `className` and does not support a `className` function. In order to support both, this component grabs the string `className` and all other props that MUI is defining, and adds those to the conditionally loaded NavLink `className`.
 *
 * @alias module:MuiNavLink
 *
 * @param {object} props - The component props object.
 *
 * @returns {React.ReactElement} - The MuiNavLink component.
 *
 * @example
 * import { ListItem } from '@mui/material'
 * import { MuiNavLink } from 'components/Navigation/mui-nav-link'
 *
 * function MyComponent() {
 *   return (
 *     <ListItem
 *       className={classes.listItem}
 *       component={MuiNavLink}
 *       to="plans"
 *     />
 *   )
 * }
 */

export const MuiNavLink: FC<MuiNavLinkProps> = React.forwardRef(
  ({ className, ...props }, ref) => {
    return (
      <NavLink
        className={({ isActive }) =>
          classnames(className, isActive ? 'Mui-selected' : '')
        }
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        ref={ref}
      />
    )
  },
)

MuiNavLink.displayName = 'MuiNavLink'
