/**
 * @module useAnalytics
 */

import React from 'react'
import { statusTypes } from '@youversion/utils'
import { loadAnalytics } from 'api/analytics/index'
import { NullableNumber } from 'types/misc'

export interface Props {
  key: string
  organizations: SelectedOrg
  year?: NullableNumber
}

// eslint-disable-next-line jsdoc/require-returns
// eslint-disable-next-line import/prefer-default-export
export function useAnalytics<T>({ key, organizations, year }: Props) {
  const [analyticsData, setAnalyticsData] = React.useState<T>()
  const [loadingStatus, setLoadingStatus] = React.useState(statusTypes.IDLE)
  const [retryFlag, setRetryFlag] = React.useState(false)

  function retry() {
    setRetryFlag((prevState) => !prevState)
  }

  React.useEffect(() => {
    async function fetchAnalytics() {
      setLoadingStatus(statusTypes.PENDING)
      try {
        // Do not remove the await. It might warn that it's unnecessary but it is.
        const analyticsResponse = await loadAnalytics<T>({
          key,
          organizations,
          year,
        })
        setAnalyticsData(analyticsResponse)
        setLoadingStatus(statusTypes.RESOLVED)
      } catch (error) {
        if (error instanceof Error) {
          setLoadingStatus(statusTypes.REJECTED)
          // Log the error from breaking if `analytics_id` is null.
          // eslint-disable-next-line no-console
          console.error(error.message)
        }
      }
    }
    fetchAnalytics()
    // `retryFlag` is intentionally added into the dependency array
    // to trigger the useEffect to call the API.
  }, [key, organizations, retryFlag, year])

  return { analyticsData, loadingStatus, retry }
}
