import { useAnalytics } from 'hooks'
import UnresolvedResponseWrapper from 'components/Analytics/UnresolvedResponseWrapper'
import styles from '../styles.module.scss'

interface Props {
  selectedOrganization: SelectedOrg
}
export default function YoySubscribers({ selectedOrganization }: Props) {
  const { analyticsData, loadingStatus, retry } =
    useAnalytics<NumberArrayResultSet>({
      key: 'rp-sub-yoy',
      organizations: selectedOrganization,
    })

  const lastYear = analyticsData?.length
    ? analyticsData[0][0].toLocaleString()
    : '-'
  const thisYear = analyticsData?.length
    ? analyticsData[0][1].toLocaleString()
    : '-'

  const percentage = analyticsData?.length ? (
    <span style={{ color: '#6CB655' }}>+{analyticsData?.[0]?.[2]}%</span>
  ) : (
    <span style={{ color: '#F73867' }}>-{analyticsData?.[0]?.[2]}%</span>
  )

  return (
    <div className={styles.yoyBox}>
      <div className={styles.header}>LAST 28 DAYS vs LAST YEAR</div>
      <div className={styles.subheader}>Subscribers</div>
      <div className={styles.outerWrapper}>
        <UnresolvedResponseWrapper retry={retry} status={loadingStatus}>
          <>
            <div className={styles.innerWrapper}>
              <div>
                <div className={styles.number}>{lastYear}</div>
                <div className={styles.subtext}>LAST YEAR</div>
              </div>

              <div>
                <div className={styles.number}>{thisYear}</div>
                <div className={styles.subtext}>THIS YEAR</div>
              </div>
            </div>
            <div className={styles.percentWrapper}>{percentage} CHANGE</div>
          </>
        </UnresolvedResponseWrapper>
      </div>
    </div>
  )
}
