// https://thenounproject.com/search/?q=info&i=794571
const ErrorInfo = () => {
  return (
    <svg
      aria-label="error-icon"
      viewBox="0 0 20 25"
      x="0px"
      xmlSpace="preserve"
      y="0px"
    >
      <g>
        <path
          d="M10,0C4.5,0,0,4.5,0,10c0,5.5,4.5,10,10,10c5.5,0,10-4.5,10-10C20,4.5,15.5,0,10,0z M10,18   c-4.4,0-8-3.6-8-8c0-4.4,3.6-8,8-8c4.4,0,8,3.6,8,8C18,14.4,14.4,18,10,18z"
          fill="#CD5C5C"
        />
        <rect fill="#CD5C5C" height="2" width="2" x="9" y="4" />
        <rect fill="#CD5C5C" height="8" width="2" x="9" y="8" />
      </g>
    </svg>
  )
}

ErrorInfo.displayName = 'Icons/ErrorInfo'

export default ErrorInfo
